import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaLightbulb, FaRedo, FaArrowRight, FaChevronDown, FaArrowLeft } from 'react-icons/fa';
import { ChessPuzzle } from '@react-chess-tools/react-chess-puzzle';
import './PuzzleButtons.css';
import './PuzzleLayout.css'; // We'll define new layout styles here
import LoadingAnimation from './LoadingAnimation'; // Import the loading animation

// lichess puzzle test data
// const puzzles = [
//   {
//     players: {
//       white: {
//         user: { name: 'grandamateur1', id: 'grandamateur1' },
//         rating: 1721,
//         ratingDiff: 7,
//         analysis: { inaccuracy: 3, mistake: 2, blunder: 7, acpl: 103 }
//       },
//       black: {
//         user: { name: 'dingodan721', id: 'dingodan721' },
//         rating: 1380,
//         ratingDiff: -2,
//         analysis: { inaccuracy: 4, mistake: 3, blunder: 8, acpl: 122 }
//       }
//     },
//     speed: 'rapid',
//     fen: '4rrk1/ppp2p2/6p1/q2N3Q/3P4/P1P1P3/1P1K4/6R1 b - - 0 25',
//     moves: [
//       'Qa4',   'Qh6',
//       'Qa6',   'Rh1',
//       'Qe2+',  'Kxe2',
//       'Rxe3+', 'Nxe3'
//     ],
//     judgement: 'Blunder',
//     mistakeMove: 'Nf6+',
//     evalDrop: NaN,
//     mate: 5,
//     makeFirstMove: true
//   },
//   {
//     players: {
//       white: {
//         user: { name: 'grandamateur1', id: 'grandamateur1' },
//         rating: 1721,
//         ratingDiff: 7,
//         analysis: { inaccuracy: 3, mistake: 2, blunder: 7, acpl: 103 }
//       },
//       black: {
//         user: { name: 'dingodan721', id: 'dingodan721' },
//         rating: 1380,
//         ratingDiff: -2,
//         analysis: { inaccuracy: 4, mistake: 3, blunder: 8, acpl: 122 }
//       }
//     },
//     speed: 'rapid',
//     fen: '8/pp1r1p2/5k2/2p3Q1/3P4/P1PqP3/1P6/2K3R1 b - - 0 42',
//     moves: [ 'Ke6', 'Qe5#' ],
//     judgement: 'Blunder',
//     mistakeMove: 'Qg4+',
//     evalDrop: NaN,
//     mate: 1,
//     makeFirstMove: true
//   },
//   {
//     players: {
//       white: {
//         user: { name: 'ashishtiwarivds', id: 'ashishtiwarivds' },
//         rating: 1636,
//         ratingDiff: 35,
//         analysis: { inaccuracy: 3, mistake: 0, blunder: 1, acpl: 33 }
//       },
//       black: {
//         user: { name: 'grandamateur1', id: 'grandamateur1' },
//         rating: 1760,
//         ratingDiff: -39,
//         analysis: { inaccuracy: 5, mistake: 3, blunder: 0, acpl: 60 }
//       }
//     },
//     speed: 'rapid',
//     fen: '2k1r1r1/pq1b1Q2/1ppP2n1/8/4PP2/8/PP1N2PP/2R1KB1R w K - 0 21',
//     moves: [ 'e5', 'Nxe5' ],
//     judgement: 'Mistake',
//     mistakeMove: 'Ref8',
//     evalDrop: 180,
//     mate: undefined,
//     makeFirstMove: true
//   },
//   {
//     players: {
//       white: {
//         user: { name: 'dostoevsky_karamazov', id: 'dostoevsky_karamazov' },
//         rating: 1534,
//         ratingDiff: -5,
//         analysis: { inaccuracy: 11, mistake: 2, blunder: 4, acpl: 44 }
//       },
//       black: {
//         user: { name: 'grandamateur1', id: 'grandamateur1' },
//         rating: 1689,
//         ratingDiff: 23,
//         provisional: true,
//         analysis: { inaccuracy: 7, mistake: 3, blunder: 3, acpl: 32 }
//       }
//     },
//     speed: 'rapid',
//     fen: '8/8/6k1/3n4/4K3/8/5p2/8 w - - 0 68',
//     moves: [
//       'Kxd5', 'Kf5',
//       'Kd6',  'f1=Q',
//       'Kc5',  'Qd3',
//       'Kc6',  'Qa6+'
//     ],
//     judgement: 'Inaccuracy',
//     mistakeMove: 'f1=Q',
//     evalDrop: NaN,
//     mate: -10,
//     makeFirstMove: true
//   }
// ]

// chess.com puzzles test data
// const puzzles = [{'evalDrop': 224,
//   'fen': 'r3kbnr/pppb1pp1/2n2q1p/4p3/2B1P3/1QP2N2/PP3PPP/RNB2RK1 b kq - 3 8',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'c1e3',
//   'moves': ['e8c8', 'c4f7', 'f8d6', 'a2a4', 'd8f8', 'f7d5', 'g8e7', 'b1d2'],
//   'players': {'black': {'user': 'shirik'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 375,
//   'fen': '2kr1bnr/pppb1pp1/2n2q1p/4p3/2B1P3/1QP2N2/PP3PPP/RNB2RK1 w - - 4 9',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'c6a5',
//   'moves': ['c1e3', 'c6a5', 'e3a7', 'a5b3', 'a2b3', 'd7c6', 'b1d2', 'f8d6'],
//   'players': {'black': {'user': 'shirik'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 581,
//   'fen': 'r3kb1r/1p1qpppp/pP3nn1/8/3P4/2N5/2P1BPPP/R1BQK2R w KQkq - 0 14',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'e7e6',
//   'moves': ['c1g5', 'a8d8', 'e1g1', 'e7e6', 'e2f3', 'f8e7', 'c3a4', 'e8g8'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 478,
//   'fen': 'r3kb1r/1p1qpppp/pP3nn1/6B1/3P4/2N5/2P1BPPP/R2QK2R b KQkq - 1 14',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'g5f6',
//   'moves': ['e7e6', 'e2b5', 'f8b4', 'b5d7', 'f6d7', 'g5d2', 'g6f4', 'd2f4'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 409,
//   'fen': 'r3kb1r/1p1q1ppp/pP2pnn1/6B1/3P4/2N5/2P1BPPP/R2QK2R w KQkq - 0 15',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'g7f6',
//   'moves': ['g5f6', 'f8b4', 'e1g1', 'b4c3', 'f6g7', 'h8g8', 'a1a3', 'c3b4'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 234,
//   'fen': 'r3kb1r/1p1q1ppp/pP2pBn1/8/3P4/2N5/2P1BPPP/R2QK2R b KQkq - 0 15',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'e1g1',
//   'moves': ['g7f6', 'e2b5', 'a6b5', 'a1a8', 'e8e7', 'd1f3', 'f6f5', 'e1g1'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 323,
//   'fen': 'r3kb1r/1p1q1p1p/pP2ppn1/8/3P4/2N5/2P1BPPP/R2QK2R w KQkq - 0 16',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'f8g7',
//   'moves': ['e1g1', 'a8d8', 'c3a4', 'f6f5', 'c2c3', 'h7h5', 'e2f3', 'g6h4'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 339,
//   'fen': 'r3kb1r/1p1q1p1p/pP2ppn1/8/3P4/2N5/2P1BPPP/R2Q1RK1 b kq - 1 16',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'd4d5',
//   'moves': ['f8g7', 'e2b5', 'a6b5', 'a1a8', 'e8e7', 'a8h8', 'g7h8', 'd1d3'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 415,
//   'fen': 'r3k2r/1p1q1pbp/pP2ppn1/8/3P4/2N5/2P1BPPP/R2Q1RK1 w kq - 2 17',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'e8g8',
//   'moves': ['d4d5', 'a8d8', 'd5e6', 'd7e6', 'e2d3', 'e8g8', 'd1f3', 'f6f5'],
//   'players': {'black': {'user': 'samirose05'},
//               'white': {'user': 'grandamateur1'}},
//   'speed': 'rapid'},
//  {'evalDrop': 132,
//   'fen': '7k/p4p1P/8/6p1/2KPBn2/P4R2/r7/8 w - - 1 51',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'a2d2',
//   'moves': ['d4d5', 'a2e2', 'c4d4', 'e2e1', 'd5d6', 'e1d1', 'e4d3', 'f4e6'],
//   'players': {'black': {'user': 'grandamateur1'},
//               'white': {'user': 'Richard2805'}},
//   'speed': 'rapid'},
//  {'evalDrop': 205,
//   'fen': '7k/p4p1P/8/3P2p1/2K1Bn2/P4R2/r7/8 b - - 0 51',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'e4d3',
//   'moves': ['a2d2', 'f3b3', 'f4g6', 'e4g6', 'f7g6', 'b3d3', 'd2c2', 'c4b5'],
//   'players': {'black': {'user': 'grandamateur1'},
//               'white': {'user': 'Richard2805'}},
//   'speed': 'rapid'},
//  {'evalDrop': 145,
//   'fen': '7k/p4p1P/8/3P2p1/2K1Bn2/P4R2/3r4/8 w - - 1 52',
//   'judgement': 'Blunder',
//   'makeFirstMove': true,
//   'mate': undefined,
//   'mistakeMove': 'f4d3',
//   'moves': ['e4d3', 'd2d1', 'd5d6', 'd1c1', 'c4b4', 'c1c8', 'd3f5', 'f4e6'],
//   'players': {'black': {'user': 'grandamateur1'},
//               'white': {'user': 'Richard2805'}},
//   'speed': 'rapid'}]

function ChessPuzzleWrapper() {
  const location = useLocation();
  const navigate = useNavigate();
  const [puzzles, setPuzzles] = useState([]);
  const [puzzleIndex, setPuzzleIndex] = useState(0);
  const [showMetadata, setShowMetadata] = useState(false);
  const [error, setError] = useState(null);
  const [completePuzzleSet, setCompletePuzzleSet] = useState(false);

  const { username, selectedMonth, selectedYear } = location.state;
  // console.log(`location.state: ${JSON.stringify(location.state)}`);

  // Construct composite keys for puzzles and puzzle index.
  const cacheKeyPuzzles = `puzzles_${username}_${selectedYear}_${selectedMonth}`;
  const cacheKeyIndex = `puzzleIndex_${username}_${selectedYear}_${selectedMonth}`;
  // console.log(`Puzzle local storage cache key: ${cacheKeyPuzzles}`);

  useEffect(() => {
    if (!username) return;

    // 1) Check if there's a stored puzzleIndex for this username+year+month
    const storedIndex = localStorage.getItem(cacheKeyIndex);
    if (storedIndex !== null) {
      const parsedIndex = parseInt(storedIndex, 10);
      if (!isNaN(parsedIndex)) {
        setPuzzleIndex(parsedIndex);
      }
    }

    // 2) Check localStorage for cached puzzles for this combination.
    const cachedPuzzles = localStorage.getItem(cacheKeyPuzzles);
    if (cachedPuzzles) {
      const parsed = JSON.parse(cachedPuzzles);
      setPuzzles(parsed);
    } else {
      // If not in cache, fetch from server
      fetchPuzzles();
    }
  }, [username, selectedYear, selectedMonth]);

  // Whenever puzzleIndex changes, save it to localStorage and fetch more puzzles if needed.
  useEffect(() => {
    console.log(`puzzleIndex: ${puzzleIndex}`)
    if (username) {
      const cacheKeyIndex = `puzzleIndex_${username}_${selectedYear}_${selectedMonth}`;
      localStorage.setItem(cacheKeyIndex, puzzleIndex.toString());
    }
    console.log(`completePuzzleSet: ${completePuzzleSet}`)
    // If all puzzles have been completed, but there are still more puzzles to grab from DB
    if (!completePuzzleSet && puzzleIndex === puzzles.length - 1) {
      console.log("test")
      fetchPuzzles();
    }
  }, [username, selectedYear, selectedMonth, puzzleIndex]);

  const fetchPuzzles = async () => {
    try {
      let dateParam = `?date=${selectedYear}-${selectedMonth}`;
      if (!selectedYear) {
        dateParam = `?date=`;
      }
      const body = {
        idToken: localStorage.getItem('google_id_token'),
      }
      const response = await fetch(
        `https://dwlgrbp17c.execute-api.us-east-1.amazonaws.com/${username}${dateParam}`,
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        }
      );
      if (!response.ok) {
        const errorResponse = await response.json().catch(() => ({
          error: response.statusText,
        }));
        throw new Error(
          `Error Generating the Puzzles: ${JSON.stringify(errorResponse)}`
        );
      }
      const data = await response.json();
      if (data.puzzles.length === 0) {
        throw new Error(`No puzzles found for user: ${username} and time period: ${dateParam}.`);
      }
      if (data.completePuzzleSet) {
        setCompletePuzzleSet(true);
      }
      // Save to state
      setPuzzles(data.puzzles);
      // Also store in localStorage under the composite key.
      localStorage.setItem(cacheKeyPuzzles, JSON.stringify(data.puzzles));
    } catch (error) {
      console.error(`Error Fetching the Puzzles: ${error}`);
      setError(
        `Not enough lichess analyzed games found for ${username} to generate puzzles, use the free lichess analysis tool on some of your games and then try again!`
      );
    }
  };

  // If an error exists, show the error message and back button
  if (error) {
    return (
      <div className="puzzle-layout">
        {/* Back button - keep it visible even when there's an error */}
        <button 
          className="back-button" 
          onClick={() => navigate('/')}
        >
          <FaArrowLeft /> Back
        </button>
        
        <p className="error-message">
          {error}
        </p>
      </div>
    );
  }

  const puzzle = puzzles.length > 0 ? puzzles[puzzleIndex] : null;
  const puzzleNumber = puzzleIndex + 1; // or use puzzle?.id if you have an ID

  return (
    <div className="puzzle-layout">
      {/* Back button */}
      <button 
        className="back-button" 
        onClick={() => navigate('/')}
      >
        <FaArrowLeft /> Back
      </button>
      
      {/* Mobile header */}
      <header className="mobile-header">
        {puzzle ? (
          <>
            <h2>Puzzlify</h2>
            <h2>Puzzle # {puzzleNumber} of {puzzles.length}</h2>
            <div className="metadata-dropdown">
              <button
                className="dropdown-toggle"
                onClick={() => setShowMetadata(!showMetadata)}
              >
                <FaChevronDown
                  className={`dropdown-icon ${showMetadata ? 'open' : ''}`}
                />
              </button>
              {showMetadata && (
                <div className="metadata-content">
                  <p><strong>White:</strong> <p2>{puzzle.players?.white?.user?.name || 'N/A'}</p2></p>
                  <p><strong>Black:</strong> <p2>{puzzle.players?.black?.user?.name || 'N/A'}</p2></p>
                  <p><strong>Game Type:</strong> <p2>{puzzle.speed || 'N/A'}</p2></p>
                  <p><strong>Mistaken Move:</strong> <p2>{puzzle.mistakeMove || 'N/A'}</p2></p>
                  {puzzle.evalDrop && <p><strong>Eval Drop:</strong> <p2>{puzzle.evalDrop || 'N/A'}</p2></p>}
                  {puzzle.mate && <p><strong>Missed Mate in:</strong> <p2>{puzzle.mate}</p2></p>}
                </div>
              )}
            </div>
          </>
        ) : (
          <LoadingAnimation />
        )}
      </header>


      {/* Left sidebar (hidden on mobile) */}
      <aside className="sidebar left-sidebar">
        {/* Brand element for the sidebar */}
        <div className="brand-sidebar">
          <h1>Puzzlify</h1>
        </div>
        {puzzle ? (
          <>
            <h2><strong>Puzzle #</strong> {puzzleNumber} of {puzzles.length}</h2>
          </>
        ) : (
          <p> </p>
        )}
      </aside>

      {/* Center section with puzzle board and buttons */}
      <main className="puzzle-center">

        {puzzles.length === 0 ? (
         <LoadingAnimation />
        ) : (
          <ChessPuzzle.Root puzzle={puzzle}>
            <ChessPuzzle.Board />

            {/* RESTART Button */}
            <ChessPuzzle.Reset asChild>
              <button className="restart-button">
                <FaRedo className="button-icon" />
                Restart
              </button>
            </ChessPuzzle.Reset>

            {/* NEXT Button */}
            <ChessPuzzle.Reset
              asChild
              puzzle={puzzles[(puzzleIndex + 1) % puzzles.length]}
              onReset={() => {
                setPuzzleIndex((puzzleIndex + 1) % puzzles.length);
              }}
            >
              <button className="next-button">
                <FaArrowRight className="button-icon" />
                Next
              </button>
            </ChessPuzzle.Reset>

            {/* HINT Button */}
            <ChessPuzzle.Hint asChild>
              <button className="next-button">
                <FaLightbulb className="button-icon" />
                Hint
              </button>
            </ChessPuzzle.Hint>
          </ChessPuzzle.Root>
        )}
      </main>

      {/* Right sidebar (hidden on mobile) */}
      <aside className="sidebar right-sidebar">
        {puzzle ? (
          <>
            <h2>Puzzle Details</h2>
            <p><strong>White:</strong> {puzzle.players.white.user.name}</p>
            <p><strong>Black:</strong> {puzzle.players.black.user.name}</p>
            <p><strong>Game Type:</strong> {puzzle.speed}</p>
            <p><strong>{puzzle.judgement} Played in Game:</strong> {puzzle.mistakeMove}</p>
            {puzzle.evalDrop && <p><strong>Eval Dropped due to Mistake:</strong> {puzzle.evalDrop}</p>}
            {puzzle.mate && <p><strong>Missed Mate in</strong> {puzzle.mate}</p>}
          </>
        ) : (
          <p> </p>
        )}
      </aside>
    </div>
  );
}

export default ChessPuzzleWrapper;
