import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ChessPuzzle from './ChessPuzzleWrapper';
import LandingPage from './LandingPage';
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/puzzle" element={<ChessPuzzle />} />
          {/* <Route path="/" element={<ChessPuzzle />} /> */}
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
