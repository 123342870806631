import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

function PaymentModal({ clientSecret, onClose, onSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (stripe && elements) {
      setReady(true);
    }
  }, [stripe, elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements || !ready) return;
  
    setProcessing(true);
    setError('');
  
    try {
      // 1) Submit the Payment Element data for validation
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setError(submitError.message);
        setProcessing(false);
        return;
      }
  
      // 2) Now confirm (setup or payment)
      if (clientSecret.startsWith('seti_')) {
        // SetupIntent
        const { setupIntent, error: setupError } = await stripe.confirmSetup({
          elements,
          clientSecret,
          redirect: 'if_required',
        });
        if (setupError) {
          setError(setupError.message);
          setProcessing(false);
          return;
        }
        onSuccess();
      } else {
        // PaymentIntent
        const { paymentIntent, error: paymentError } = await stripe.confirmPayment({
          elements,
          clientSecret,
          redirect: 'if_required',
        });
        if (paymentError) {
          setError(paymentError.message);
          setProcessing(false);
          return;
        }
        onSuccess();
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Enter Payment Details</h2>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit" disabled={processing || !stripe || !elements || !ready}>
            {processing ? 'Processing...' : 'Pay Now'}
          </button>
        </form>
        <button onClick={onClose} style={{ marginTop: '8px' }}>
          Close
        </button>
      </div>
    </div>
  );
}

export default PaymentModal;
