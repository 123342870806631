// LoadingAnimation.jsx
import React from 'react';
import { FaChessKing } from 'react-icons/fa';
import './LoadingAnimation.css';

const LoadingAnimation = () => {
  return (
    <div className="loading-container">
      <FaChessKing className="loading-icon" />
      <p>Generating Puzzles...</p>
    </div>
  );
};

export default LoadingAnimation;
