// LandingPage.jsx

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import PaymentModal from './PaymentModal'; // from above
import './PaymentModal.css'; // your modal styling

import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaChessPawn, FaChessQueen } from 'react-icons/fa';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import './LandingPage.css';

// Load your Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function LandingPage() {
  const [username, setUsername] = useState('');
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('free');
  const [searchParams] = useSearchParams();

  const [clientSecret, setClientSecret] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const navigate = useNavigate();

  // We consider a user "paid" if subscriptionStatus is "active" (or "paid").
  const isPaid = userInfo ? true : false;
  // const isPaid = subscriptionStatus === 'active';

  // --- 1) On initial mount, try to restore user from localStorage ---
  useEffect(() => {
    const token = localStorage.getItem('google_id_token');
    if (token) {
      const decoded = jwtDecode(token);
      // Optional: Check if token is expired
      if (decoded.exp && decoded.exp * 1000 > Date.now()) {
        setUserInfo(decoded);
      } else {
        // Token is expired or invalid, remove it
        localStorage.removeItem('google_id_token');
      }
    }
  }, []);
  // Check subscription status on mount and when returning from payment
  // useEffect(() => {
  //   const checkPaymentStatus = async () => {
  //     if (userInfo?.email) {
  //       // Add small delay to allow Stripe webhook to process
  //       if (searchParams.get('payment_status') === 'success') {
  //         await new Promise(resolve => setTimeout(resolve, 2000));
  //       }
  //       await checkUserSubscriptionStatus(userInfo.email);
  //     }
  //   };

  //   checkPaymentStatus();
  // }, [userInfo, searchParams]);

  // // 1) Function to call your backend to see if user is paid
  // const checkUserSubscriptionStatus = async (email) => {
  //   try {
  //     // Example request: 
  //     // Adjust the URL to your actual Lambda or API Gateway endpoint
  //     const response = await fetch(`https://hsp1rr5g7l.execute-api.us-east-1.amazonaws.com/${email}`, {
  //       method: 'GET',
  //     });
  //     if (!response.ok) {
  //       // If the endpoint returns an error, handle it
  //       console.error('Error checking subscription status:', response.status);
  //       return;
  //     }

  //     const data = await response.json();
  //     // Suppose the response JSON looks like: { subscriptionStatus: "active" }
  //     if (data.subscriptionStatus) {
  //       setSubscriptionStatus(data.subscriptionStatus);
  //     } else {
  //       setSubscriptionStatus('free');
  //     }
  //   } catch (err) {
  //     console.error('Error fetching subscription status:', err);
  //   }
  // };

  const handleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    setUserInfo(decoded);

    // Optionally store the token for future requests
    localStorage.setItem('google_id_token', token);
    // console.log('Decoded Google user info:', decoded);
  };

  const handleLoginError = () => {
    alert('Google Login Failed. Please try again.');
  };

  // Called when user wants to open the embedded subscription form
  const handleOpenPaymentModal = async () => {
    if (!userInfo) {
      alert('Please sign in with Google first.');
      return;
    }
    try {
      // 1) Call your Node/Lambda route that creates a subscription
      const response = await fetch('https://o5s6iz75t2.execute-api.us-east-1.amazonaws.com/create-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: userInfo.email }),
      });
      if (!response.ok) {
        const errMsg = await response.text();
        throw new Error(errMsg);
      }
      const data = await response.json();
      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
        setShowPaymentModal(true);
      } else {
        alert('No clientSecret returned by the server.');
      }
    } catch (err) {
      console.error('Error creating subscription:', err);
      alert('Could not start the payment flow. Please check the console.');
    }
  };

  // Called after PaymentModal completes successfully
  const handlePaymentSuccess = () => {
    setShowPaymentModal(false);
    setSubscriptionStatus('active');
    alert('Payment success! You are now paid.');
  };

  const handleCloseModal = () => {
    setShowPaymentModal(false);
  };

  // 1) Called when user wants to "unlock month access" by paying
  // 2) This hits our /create-checkout-session endpoint, which returns a session URL
  // 3) We redirect the user to Stripe Checkout
  // const handleOpenCheckoutSession = async () => {
  //   if (!userInfo) {
  //     alert('Please sign in with Google first.');
  //     return;
  //   }
  //   try {
  //     const response = await fetch('https://o5s6iz75t2.execute-api.us-east-1.amazonaws.com/create-checkout-session', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ email: userInfo.email }), 
  //       // Or pass any other user info your backend needs
  //     });
  //     if (!response.ok) {
  //       const errMsg = await response.text();
  //       throw new Error(errMsg);
  //     }
  //     const data = await response.json();
      
  //     if (data.url) {
  //       // Redirect to the Stripe-hosted checkout
  //       window.location.href = data.url;
  //     } else {
  //       alert('No Checkout Session URL returned by the server.');
  //     }
  //   } catch (err) {
  //     console.error('Error creating checkout session:', err);
  //     alert('Could not start the payment flow. Please try again.');
  //   }
  // };

  const handleSubmit = () => {
    if (!username.trim()) {
      alert('Please enter a valid Lichess username.');
      return;
    }

    // If one of year/month is provided but not both, show an error
    if ((selectedMonth && !selectedYear) || (selectedYear && !selectedMonth)) {
      alert('Please select both a year and a month, or leave both blank.');
      return;
    }

    // Navigate to /puzzle, passing username, year, and month in location state
    navigate('/puzzle', { state: { username, selectedYear, selectedMonth } });
  };

  const handleSliderChange = (e) => {
    const val = Number(e.target.value);
    setSliderValue(val);
    if (val === 100) {
      handleSubmit();
      setSliderValue(0);
    }
  };

  // Build options for year and month dropdowns
  const yearOptions = ['', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
  const monthOptions = [
    { value: '', label: '' },
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  return (
    <div className="landing-container">
      {/* Brand Header */}
      <header className="brand-header">
        <h2>Puzzlify</h2>
      </header>

      {/* Move Google login and payment button to top right */}
      <div className="auth-section">
        {!userInfo ? (
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginError}
          />
        ) : (
          <p>Welcome, {userInfo.name}!</p>
        )}
        
      {/* Payment button - only show if user not paid */}
      {!isPaid && userInfo && (
        <button onClick={handleOpenPaymentModal}>Unlock Month Access</button>
      )}
      </div>

      <h1>
        Create Puzzles from Your Blunders and Misses.
        <br />
        Study Your Mistakes, Find the Solutions
      </h1>

      <div className="input-container">
        <input
          type="text"
          placeholder="Enter your Lichess username"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      {/* Year and month selection (disabled if not paid) */}
      <div className="date-selectors">
        {!isPaid && <div className="upgrade-tooltip">Sign In to generate puzzles by month</div>}
        <div className="selectors-wrapper">
          <label>
            Year:&nbsp;
            <select
              value={selectedYear || ''}
              onChange={(e) => setSelectedYear(e.target.value || null)}
              disabled={!isPaid}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
          &nbsp;&nbsp;
          <label>
            Month:&nbsp;
            <select
              value={selectedMonth || ''}
              onChange={(e) => setSelectedMonth(e.target.value || null)}
              disabled={!isPaid}
            >
              {monthOptions.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      {/* Slider container */}
      <div className="slider-button">
        <FaChessPawn
          className="icon pawn-icon"
          style={{ opacity: 1 - sliderValue / 100 }}
        />
        <FaChessQueen
          className="icon queen-icon"
          style={{ opacity: sliderValue / 100 }}
        />
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          value={sliderValue}
          onChange={handleSliderChange}
        />
      </div>

      <p className="slider-instructions">
        Slide to the right to generate puzzles
      </p>

      {/* Payment Modal with Stripe Elements */}
      {showPaymentModal && (
        <div className="payment-modal">
          {/* We must wrap PaymentModal in <Elements> with the clientSecret */}
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentModal
              clientSecret={clientSecret}
              onClose={handleCloseModal}
              onSuccess={handlePaymentSuccess}
            />
          </Elements>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
